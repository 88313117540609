import { useSearchParams } from 'react-router-dom'

function getQueryVariable(variable) {
	var query = window.location.search.substring(1)
	var vars = query.split("&")

	for (var i=0;i<vars.length;i++) {
		var pair = vars[i].split("=")
		if(pair[0] == variable){return pair[1]}
	}

	return undefined
}

console.log(1337, window.location.href)

//? window.location.href.slice(0, -1)

const config = {
	apiHost: getQueryVariable('host') || (
		window.location.host.includes('ngrok-free.app') 
		? `https://${window.location.host}`
		: 'https://x172877015052069.xe.am'
	),
	mapKey: getQueryVariable('map_key') || '84d81ca3-dda0-40f7-9093-eba709589810'
}

if(!config.apiHost.startsWith('http')) config.apiHost = `https://${config.apiHost}`

console.log(1337, config)

export {config}

// const debug = 0
// export const config = debug ? {
// 	mapKey: '4abda54d-e5c7-4487-916a-480f91df2ac9',
// 	apiHost: 'https://aa92-37-120-233-238.ngrok-free.app'
// } : {
// 	mapKey: '84d81ca3-dda0-40f7-9093-eba709589810',
// 	apiHost: 'https://x171523385766936.xe.am'
// }